import {ActionWithPayload} from "../types/redux/ActionWithPayload";
import {CityFilterValue, Filter, FilterSearch, FilterValue} from "../types/types";
import {
    SET_ACTIVE_FILTERS,
    SET_COUNTRY_FILTERS,
    SET_REGION_FILTERS,
    SET_CITY_FILTERS,
    SET_VACATION_TYPE_FILTERS,
    SET_AVAILABLE_FILTERS
} from "../actions";

export interface FilterReducerState {
    selectedFilters: Array<FilterSearch>;
    countryFilters: Array<FilterValue>;
    regionFilters: Array<FilterValue>;
    cityFilters: Array<CityFilterValue>;
    vacationTypeFilters: Array<FilterValue>;
    availableFilters: Array<Filter>;
    filterChanged: boolean;
}

const initialState: FilterReducerState = {
    selectedFilters: [],
    countryFilters: [],
    regionFilters: [],
    cityFilters: [],
    vacationTypeFilters: [],
    availableFilters: [],
    filterChanged: false
}

const FilterReducer = (state: FilterReducerState = initialState, action: ActionWithPayload<any>): FilterReducerState => {
    switch (action.type) {
        case SET_ACTIVE_FILTERS:
            return {
                ...state,
                selectedFilters: action.payload,
                filterChanged: true
            }
        case SET_COUNTRY_FILTERS:
            return {
                ...state,
                countryFilters: action.payload
            }
        case SET_REGION_FILTERS:
            return {
                ...state,
                regionFilters: action.payload
            }
        case SET_CITY_FILTERS:
            return {
                ...state,
                cityFilters: action.payload
            }
        case SET_VACATION_TYPE_FILTERS:
            return {
                ...state,
                vacationTypeFilters: action.payload
            }
        case SET_AVAILABLE_FILTERS:
            return {
                ...state,
                availableFilters: action.payload
            }

        default:
            return state;
    }
}

export default FilterReducer;