import {FilterSearch, SortOptions, Vacation} from "../types/types";
import {apiService} from "./ApiService";
import UrlUtil from "../utils/UrlUtil";
import RangeUtil from "../utils/RangeUtil";
import FilterUtil from "../utils/FilterUtil";

class VacationService {
    public getFilteredVacations(
        activeFilters: FilterSearch[],
        participantAmount: number,
        sortOptions: SortOptions = null,
        useDefaultFilters: boolean,
        page: number | string,
        limit: number,
        urlParams: string
    ): Promise<{result: Vacation[], count: number} | null> {
        return new Promise((resolve, reject) => {
            const filters: FilterSearch[] = this.formatFilters(activeFilters, urlParams, useDefaultFilters);
            const url: string = this.buildUrl(filters, participantAmount, sortOptions, page, limit);

            apiService.call<{result: Vacation[], count: number}>(url)
                .then(response => {
                    return resolve(response.data);
                }).catch(reject);
        });
    }

    private formatFilters(activeFilters: FilterSearch[], urlParams: string, useDefaultFilters: boolean): FilterSearch[] {
        activeFilters = UrlUtil.parseUrlParamsToFilters(urlParams);

        if (activeFilters.length === 0 && useDefaultFilters) {
            activeFilters = FilterUtil.getDefaultFilters();
        }
        activeFilters = FilterUtil.removeUnderScores(activeFilters);

        return activeFilters;
    }

    private buildUrl(activeFilters: FilterSearch[], participantAmount: number, sortOptions: SortOptions | null, page: string | number, limit: number): string {
        let url = UrlUtil.formatUrlParams('vacation', activeFilters, [...RangeUtil.RANGE_FILTERS]);

        url = RangeUtil.addRangeFiltersToUrl(activeFilters, url);
        if (activeFilters.length > 0) {
            url = UrlUtil.addParticipantAmountToUrlParams(url, participantAmount);
            if (sortOptions !== null) {
                url = UrlUtil.addSortingToUrlParams(url, sortOptions);
            }
        }

        url = url.concat(`${activeFilters.length === 0 ? "?" : "&"}page=${page}&limit=${limit}`);

        return url;
    }
}

export const vacationService: VacationService = new VacationService();