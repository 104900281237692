import React from "react";
import SelectCity from "./SelectCity/SelectCity";
import {FilterSearch} from "../../../../types/types";
import "./SelectRegion.scss";

interface SelectRegionProps {
    show: boolean;
    country: FilterSearch | undefined;
    countryCount: number;
    setRegion: (value: string | undefined) => void;
    setCity: (value: string | undefined) => void;
    regions: Array<{ displayValue: string, value: string, count: number }>;
    cities: Array<{ displayValue: string, value: string, region: string, count: number }>;

}

const SelectRegion: React.FC<SelectRegionProps> = ({
    show,
    country,
    countryCount,
    setRegion,
    setCity,
    regions,
    cities
}) => {

    if (!show) {
        return null;
    }

    if (country.value === "allCountries") {
        return (
            <div className="region-wrapper">
                <h5>{country.displayValue} ({countryCount})</h5>
                <hr />
                <b><i>Selecteer een land om op plaats te filteren</i></b>
            </div>
        )
    }

    return (
        <div className="region-wrapper">
            <h5>{country.displayValue} ({countryCount})</h5>
            <div className="select-btn-wrapper">
                {
                    regions?.map((region, index) => {
                        return <SelectCity
                            key={index}
                            region={region}
                            setCity={setCity}
                            setRegion={setRegion}
                            cities={cities}
                        />
                    })
                }
            </div>
        </div>

    );
}

export default SelectRegion;