import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import ClearAnswer from "../common/ClearAnswer/ClearAnswer";
import FilterBar from "./Filter/Filter";
import HaveYou from "./HaveYou/HaveYou";
import SearchHero from "./Hero/Hero";
import TravelPackage from "./TravelPackage/TravelPackage";
import {FilterSearch, SortOptions, Vacation} from "../../types/types";
import {vacationService} from "../../services/VacationService";
import {useDispatch, useSelector} from "react-redux";
import {ReducerStateTypes} from "../../types/redux/ReducerStateTypes";
import {useSearchParams} from "react-router-dom";
import {setVacationResults} from "../../actions";

interface SearchProps {
    showFaq: boolean;
    showHaveYou: boolean;
    hideHeader: boolean;
}

const Search: React.FC<SearchProps> = ({
    showFaq,
    showHaveYou,
    hideHeader
                                       }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const selectedFilters: FilterSearch[] = useSelector((state: ReducerStateTypes) => state.filter.selectedFilters);
  const participantAmountAdults: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountAdults);
  const participantAmountChildren: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountChildren);
  const currentSortOptions: SortOptions | null = useSelector((state: ReducerStateTypes) => state.sorting.currentSortOption);
  const filterChanged: boolean = useSelector((state: ReducerStateTypes) => state.filter.filterChanged);
  const pageLimit: number = useSelector((state: ReducerStateTypes) => state.page.pageLimit);
  const vacationResults: { result: Vacation[]; count: number } = useSelector((state: ReducerStateTypes) => state.vacation.vacationResults);

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  useEffect(() => {
    vacationService.getFilteredVacations(
        selectedFilters,
        (participantAmountAdults + participantAmountChildren),
        currentSortOptions,
        !filterChanged,
        searchParams.get("page"),
        pageLimit,
        searchParams.get("filter") ?? ""
    ).then(vacations => {
      dispatch(setVacationResults(vacations));
    });
  }, [currentSortOptions, dispatch, filterChanged, pageLimit, participantAmountAdults, participantAmountChildren, searchParams, selectedFilters]);

  return (
    <>
      <SearchHero handleShow={handleShow} hideHeader={hideHeader}/>
      <TravelPackage
        vacations={vacationResults.result}
        vacationCount={vacationResults.count}
      />

        { showFaq && <ClearAnswer/>}
        { showHaveYou && <HaveYou/>}

      <>
        <Offcanvas
          className="filter-offcanvas"
          show={show}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Filtering</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <FilterBar />
          </Offcanvas.Body>
        </Offcanvas>
      </>
    </>
  );
};

export default Search;
