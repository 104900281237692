import {ActionWithPayload} from "../types/redux/ActionWithPayload";
import {SET_VACATION_RESULTS} from "../actions";
import {Vacation} from "../types/types";

export interface VacationReducerState {
    vacationResults: { result: Vacation[], count: number };
}

const initialState: VacationReducerState = {
    vacationResults: { result: [], count: 0 },
}

const VacationReducer = (state: VacationReducerState = initialState, action: ActionWithPayload<any>): VacationReducerState => {
    switch (action.type) {
        case SET_VACATION_RESULTS:
            return {
                ...state,
                vacationResults: action.payload
            }
        default:
            return state;
    }
}

export default VacationReducer;