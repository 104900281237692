import React from "react";
import logoImage from "../../../assets/img/logo.png";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { Facebook, Instagram, TikTok } from "../../../Icons";

export default function Footer() {
  return (
    <footer id="footer">
      <div className="container">
        <div className="footer-contents">
          <div className="left-side">
            <Link to="/">
              <img src={logoImage} alt="Holiday Comparison Logo" />
            </Link>

            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              tempor mi sed dui suscipit, eu vehicula urna elementum. Nam
              molestie maximus pharetra. Vivamus augue velit, 
            </p>
          </div>

          <div className="right-side">
            <div className="item">
              <h5>Quick Links</h5>
              <Link to="/">Home</Link>
              <Link to="/">About Us</Link>
              <Link to="/">Testimonials</Link>
            </div>
            <div className="item">
              <h5>Support</h5>
              <Link to="/">Contact Us</Link>
              <Link to="/">FAQ’s</Link>
              <Link to="/">Blogs</Link>
            </div>
            <div className="item">
              <h5>Get Updates</h5>
              <div className="input-box">
                <input type="email" placeholder="Enter your email" />
                <button>Subscribe</button>
              </div>

              <div className="social-media-icons">
                {" "}
                <Link to="/">
                  <Facebook />
                </Link>
                <Link to="/">
                  <Instagram />
                </Link>
                <Link to="/">
                  <TikTok />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <span className="copyright-text">©2024 Vergelijkjevakantie.nl</span>
      </div>
    </footer>
  );
}
