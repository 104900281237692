import React, {useEffect, useState} from "react";
import {CityFilterValue, Filter, FilterSearch} from "../../../types/types";
import {useDispatch, useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../types/redux/ReducerStateTypes";
import {useSearchParams} from "react-router-dom";
import {filterService} from "../../../services/FilterService";
import {FilterCategory} from "../../../types/filter/FilterCategory";
import SearchResultsSearchBar from "../../search/SearchBar/SearchResultsSearchBar";
import HomeSearchBar from "../../home/SearchBar/HomeSearchBar";
import {
    setAvailableFilters,
    setCityFilters,
    setCountryFilters,
    setRegionFilters,
    setVacationTypeFilters
} from "../../../actions";

interface SearchBarProps {
    isSearchPage?: boolean;
    handleShow?: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
                                                 isSearchPage = false,
                                                 handleShow
                                             }) => {

    const selectedFilters: FilterSearch[] = useSelector((state: ReducerStateTypes) => state.filter.selectedFilters);
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();

    useEffect(() => {
        filterService.getFilters(selectedFilters, searchParams.get("filter") ?? "").then(filters => {
            if (filters.length === 0) return;
            dispatch(setAvailableFilters(filters));

            const countries: Filter = filters.find(filter => filter.filterType === FilterCategory.COUNTRY);
            const regions: Filter = filters.find(filter => filter.filterType === FilterCategory.REGION);
            const cities: Filter = filters.find(filter => filter.filterType === FilterCategory.CITY);
            const types: Filter = filters.find(filter => filter.filterType === FilterCategory.VACATION_TYPE && filter.filterCategory === "vacation");

            dispatch(setCountryFilters(countries.values));
            dispatch(setRegionFilters(regions.values));
            dispatch(setCityFilters(cities.values as CityFilterValue[]));
            dispatch(setVacationTypeFilters(types.values));
        });
    }, [dispatch, searchParams, selectedFilters]);

    return (
        isSearchPage ? <SearchResultsSearchBar handleShow={handleShow}/> : <HomeSearchBar/>
    );
}

export default SearchBar;