import React, {useEffect, useState} from "react";
import "./SearchBar.scss";
import {useSearchParams} from "react-router-dom";
import SelectLocation from "../../common/SelectLocation/SelectLocation";
import AddGuest from "../../common/AddGuest/AddGuest";
import EnterDate from "../../common/EnterDate/EnterDate";
import SelectDestination from "../../common/SelectDestination/SelectDestination";
import {useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../types/redux/ReducerStateTypes";
import FilterUtil from "../../../utils/FilterUtil";
import UrlUtil from "../../../utils/UrlUtil";
import searchIcon from "../../../assets/img/search-normal.svg";
import filterIcon from "../../../assets/img/filter.svg";
import searchIconSm from "../../../assets/img/searchsm-icon.svg";
import {FilterReducerState} from "../../../reducers/FilterReducer";

interface SearchResultsSearchBarProps {
    handleShow?: () => void;
}

const SearchResultsSearchBar: React.FC<SearchResultsSearchBarProps> = ({handleShow}) => {

    const filterData: FilterReducerState = useSelector((state: ReducerStateTypes) => state.filter);
    const {countryFilters, vacationTypeFilters, selectedFilters, availableFilters, regionFilters, cityFilters} = filterData;

    const participantAmountAdults: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountAdults);
    const participantsAmountChildren: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountChildren);

    const [searchParams] = useSearchParams();

    const [currentType, setCurrentType] = useState<string | undefined>(undefined);
    const [currentDestination, setCurrentDestination] = useState<string | undefined>(undefined);
    const [currentDuration, setCurrentDuration] = useState<string | undefined>(undefined);

    useEffect(() => {
      const filters = FilterUtil.removeDuplicates([...filterData.selectedFilters, ...UrlUtil.parseUrlParamsToFilters(searchParams.get("filter") ?? "")]);
      let currentType: string | undefined = filters.find(f => f.filterType.toUpperCase() === "TYPE" && f.filterCategory.toUpperCase() === "VACATION")?.displayValue;
      let currentDestination: string | undefined = filters.find(f => f.filterType.toUpperCase() === "COUNTRY")?.displayValue;
      let currentDuration: string | undefined = filters.find(f => f.filterType.toUpperCase() === "DURATION")?.displayValue;

      setCurrentType(currentType);
      setCurrentDestination(currentDestination);
      setCurrentDuration(currentDuration);
      }, [searchParams, selectedFilters]);

    return (
        <div id="seach-search-bar">
            <div className="select-items-wrapper">
                <div className="select-item ">
                    <h6>Vakantietype</h6>
                    <SelectLocation
                        showing="Selecteer vakantie type"
                        vacationTypes={vacationTypeFilters}
                    />
                </div>
                <div className="border-right"/>
                <div className="select-item t">
                    <h6>Bestemming</h6>
                    <SelectDestination
                        showing="Selecteer bestemming"
                        countries={countryFilters}
                        cities={cityFilters}
                        regions={regionFilters}
                    />
                </div>
                <div className="border-right"/>
                <div className="select-item ">
                    <h6>Wanneer</h6>
                    <EnterDate filters={availableFilters}/>
                </div>
                <div className="border-right"/>
                <div className="select-item ">
                    <h6>Wie</h6>
                    <AddGuest/>
                </div>
            </div>
            <a href={`/search?${searchParams.toString()}`} className="search-btn">
                <img src={searchIcon} alt=""/> Zoeken
            </a>
            <div className="sm-device-search-box">
                <div className="d-flex align-items-center search-text gap-3">
                    <img src={searchIconSm} alt=""/>
                    <div>
                        {" "}
                        <h5>
                            {currentType && currentDestination
                                ? `${currentType}, ${currentDestination}` :
                                currentType && !currentDestination ?
                                    `${currentType}` :
                                    !currentType && currentDestination ?
                                        `${currentDestination}` : "Selecteer bestemming"
                            }
                        </h5>
                        <p>
                            {currentDuration ? currentDuration : "Selecteer reisduur"} •
                            {participantAmountAdults || participantsAmountChildren
                                ? `${
                                    participantAmountAdults > 0
                                        ? participantAmountAdults + " Volwassenen"
                                        : ""
                                } ${
                                    participantAmountAdults > 0 && participantsAmountChildren > 0
                                        ? ","
                                        : ""
                                } ${
                                    participantsAmountChildren > 0
                                        ? participantsAmountChildren + " Kinderen"
                                        : ""
                                }`
                                : " Selecteer reisgezelschap"}
                        </p>
                    </div>
                </div>

                <a onClick={handleShow} className="filter-btn border-0">
                    <img src={filterIcon} alt=""/>
                </a>
            </div>
        </div>
    );
};

export default SearchResultsSearchBar;
