import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {Provider} from "react-redux";
import PluginOptions from "./types/PluginOptions";
import ConfigUtil from "./utils/ConfigUtil";
import store from "./store";

function renderTool(identifier: string, plugin_options: PluginOptions) {
    console.log("rendering tool with identifier: ", identifier, plugin_options);
    const root = ReactDOM.createRoot(document.getElementById(identifier) as HTMLElement);

    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <Provider store={store}>
                    <App pluginOptions={plugin_options} />
                </Provider>
            </BrowserRouter>
        </React.StrictMode>
    );
}
document.addEventListener('DOMContentLoaded', () => {
    // @ts-ignore - this is a global variable that is set by the backend
    if(window.vjv_compare_tool !== undefined && window.vjv_compare_tool.length > 0) {
        // @ts-ignore - this is a global variable that is set by the backend
        window.vjv_compare_tool.forEach((plugin_options) => {
            renderTool("vjv-compare-tool-target-"+plugin_options.identifier, plugin_options);
        });

    } else if(document.getElementById("vjv-compare-tool-target") !== null) {
        renderTool("vjv-compare-tool-target", ConfigUtil.getConfig());
    } else {
        // @ts-ignore - this is a global variable that is set by the backend
        console.error('There is no element with the id "vjv-compare-tool-target" or "vjv-compare-tool-target-"');

    }
});