import {CityFilterValue, Filter, FilterSearch, FilterValue} from "../types/types";
import {ActionWithPayload} from "../types/redux/ActionWithPayload";

export const SET_ACTIVE_FILTERS = "SET_ACTIVE_FILTERS";
export const SET_COUNTRY_FILTERS = "SET_COUNTRY_FILTERS";
export const SET_REGION_FILTERS = "SET_REGION_FILTERS";
export const SET_CITY_FILTERS = "SET_CITY_FILTERS";
export const SET_VACATION_TYPE_FILTERS = "SET_VACATION_TYPE_FILTERS";
export const SET_AVAILABLE_FILTERS = "SET_AVAILABLE_FILTERS";

export const setActiveFilters = (value: FilterSearch[]): ActionWithPayload<FilterSearch[]> => ({
    type: SET_ACTIVE_FILTERS,
    payload: value
});

export const setCountryFilters = (value: FilterValue[]): ActionWithPayload<FilterValue[]> => ({
    type: SET_COUNTRY_FILTERS,
    payload: value
});

export const setRegionFilters = (value: FilterValue[]): ActionWithPayload<FilterValue[]> => ({
    type: SET_REGION_FILTERS,
    payload: value
});

export const setCityFilters = (value: CityFilterValue[]): ActionWithPayload<CityFilterValue[]> => ({
    type: SET_CITY_FILTERS,
    payload: value
});

export const setVacationTypeFilters = (value: FilterValue[]): ActionWithPayload<FilterValue[]> => ({
    type: SET_VACATION_TYPE_FILTERS,
    payload: value
});

export const setAvailableFilters = (value: Filter[]): ActionWithPayload<Filter[]> => ({
    type: SET_AVAILABLE_FILTERS,
    payload: value
});

