import React from "react";
import Header from "../../global/header/Header";
import "./Hero.scss";
import SearchBar from "../../common/SearchBar/SearchBar";

interface SearchHeroProps {
  handleShow: () => void;
  hideHeader?: boolean;
}

const SearchHero: React.FC<SearchHeroProps> = ({
  handleShow,
  hideHeader = false,
}) => {
  return (
    <header id="search-hero">
      <div className="container">
        <div className="head-contents">
          {!hideHeader && <Header styleFor="search" />}
        </div>
        <button className="outline-btn">Wij regelen jou vakantie</button>
        <h1>Je vakantie begint hier, vergelijk en vind de beste deal</h1>
        <div className="search-box">
          <SearchBar handleShow={handleShow} isSearchPage={true} />
        </div>
      </div>
    </header>
  );
};

export default SearchHero;
