import React from "react";
import "./TravelPackage.scss";
import TravelPackageItem from "../../common/TravelPackageItem/TravelPackageItem";
import {Vacation} from "../../../types/types";
import planeImage from "../../../assets/img/plane.png";

const TravelPackage: React.FC = () => {
  return (
    <section id="travel-package">
      <div className="container">
        <div>
          <button className="outline-btn text-uppercase">
            Prices in Your Hand
          </button>
          <h1 className="section-title">
            Top travel packages of the month{" "}
            <img src={planeImage} alt="" />
          </h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>

          <div className="travel-package-wrapper">
            {tours.map((it, i) => (
              <TravelPackageItem item={it} key={i} />
            ))}
          </div>

          <button className="see-more-btn">See More</button>
        </div>
      </div>
    </section>
  );
};

export default TravelPackage;

const tours: Vacation[] = [];
