import React from "react";
import "./ExplorePlace.scss";
import Slider from "react-slick";
import worldImage from "../../../assets/img/world.png";

export default function ExplorePlace() {
  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    draggable: false,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 0,
  };
  return (
    <section id="explore-place">
      <div className="container">
        <h1 className="section-title">
          Explore favorite places with your lovely family & friends{" "}
          <img src={worldImage} alt="" />
        </h1>
      </div>
      <div>
        <div className="slider-container">
          <Slider {...settings}>
            {[
              "/assets/img/place/img2.png",
              "/assets/img/place/img3.png",
              "/assets/img/place/img4.png",
              "/assets/img/place/img1.png",
              "/assets/img/place/img5.png",
            ].map((it, i) => (
              <div key={i} className="h-100">
                {" "}
                <img className="img-fluid" src={it} alt="" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}
